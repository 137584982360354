import _ from "lodash";

export default (duration) => {
  let milliseconds = parseInt((duration % 1000) / 100),
    seconds = parseInt((duration / 1000) % 60),
    minutes = parseInt((duration / (1000 * 60)) % 60),
    hours = parseInt((duration / (1000 * 60 * 60)) % 24),
    days = parseInt(duration / (1000 * 60 * 60 * 24));

  if (duration <= 1000) {
    return Math.trunc(duration / 1000) + "s";
  }

  let p = "";

  if (days > 0) {
    p = p + _.padStart(days, 2, 0) + "d ";
  }

  if (hours > 0) {
    p = p + _.padStart(hours, 2, 0) + "h ";
  }
  if (hours > 0 || minutes > 0) {
    p = p + _.padStart(minutes, 2, 0) + "m ";
  }

  let value = (p + _.padStart(seconds, 2, 0) + "s").replace(/^0+/, "");

  if (value === "s") {
    value = "0s";
  }

  return value;
};
