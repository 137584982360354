import store from "@/store";
import router from "@/router";

var PhoneNumber = require("awesome-phonenumber");

export default function ExtensionService() {
  window.injectNumber = (number) => {
    let pn = new PhoneNumber(number, "US");
    if (router.currentRoute.path !== "/" && router.currentRoute.path !== "/engage") {
      router.push("/engage");
    }

    store.commit("phone/setNumberToDial", pn.getNumber("e164"));
  };
  return;
}
