import _ from "lodash";
const requireMP3 = require.context(".", false, /\.mp3$/);
const mp3Collections = {};

requireMP3.keys().forEach((fileName) => {
  const mp3Name = _.camelCase(fileName.replace(/(\.\/|\.mp3)/g, ""));
  mp3Collections[mp3Name] = requireMP3(fileName);
});

export default mp3Collections;
