import dateFns from "date-fns";
import { i18n } from "@/plugins/language";
import es from "date-fns/locale/es";

export default (date) => {
  if (dateFns.isToday(date)) {
    let formatted = dateFns.format(date, "h:mm A");
    return `${i18n.t("engage.todayAt")} ${formatted}`;
  }
  if (i18n.locale === "es") {
    return dateFns.format(date, "ddd, M/D/YYYY, h:mm A", { locale: es });
  }
  return dateFns.format(date, "ddd, M/D/YYYY, h:mm A");
};
