export function isValidPhoneNumber(num) {
  if (num) {
    let phoneNumber = num.replace(/\s/g, "");
    let regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    let isValidPhone = regex.test(phoneNumber);
    return isValidPhone;
  } else return num;
}

export function isValidEmail(email) {
  if (email == "" || email.indexOf("@") == -1 || email.indexOf(".") == -1) {
    return false;
  }
  return true;
}

export function isValidPassword(pass) {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\W\w]{8,}$/;
  return regex.test(pass);
}

export function deleteRemnantsOfProp(obj, props) {
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach(function (key) {
      if ((Array.isArray(props) && props.includes(key)) || (typeof props === "string" && props === key)) {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        deleteRemnantsOfProp(obj[key], props);
      }
    });
  }
}

export function isValidURL(str) {
  try {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  } catch (e) {
    console.error(e);
    return false;
  }
}
