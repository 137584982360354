export const PeerCallStatus = {
  InitialState: "InitialState",
  Connecting: "Connecting",
  Connected: "Connected",
};

export const EndpointType = {
  phone_number: { description: "phone_number", icon: "fas fa-hashtag" },
  agent: { description: "agent", icon: "fas fa-user" },
  queue: { description: "queue", icon: "fas fa-layer-group" },
};

export const ContactFilter = {
  SMS: "sms",
  EMAIL: "email",
  TASK: "task",
  CUSTOMTASK: "custom_task",
  PHONE_NUMBERS: "phoneNumber",
  AGENTS_QUEUES: "agentsQueues",
  QUICK_CONNECTS: "quickConnects",
};

export const Engage = {
  Channel: {
    VOICE: "voice",
  },
  InitiationMethod: {
    OUTBOUND: "OUTBOUND",
  },
};

export const ChatItemType = "ATTACHMENT";

export const UserStatus = {
  Hold: "Hold",
  Busy: "Busy",
};

export const videoCallContants = {
  MIC: "mic",
  AUDIO: "audio",
  VIDEO: "video",
  SHARE: "share",
  DEFAULTTILE: 1,
  INITIATOR: "initiator",
  RESOURCE_NAME: "AWS Chime video call integration",
};

export const oneClickSetup = {
  placeholder: "Email Setup Wizard",
  name: "1-Click-Email",
  type: "email",
  provider: "Amazon-SES/1-Click",
  description: "Webhook for one click email setup.",
  success: "Success",
  pending: "Pending",
  failed: "Failed",
  external: "(External Domain)",
  outbound: "Outbound",
  inbound: "Inbound",
  devDomain: "emailreceiverdev.com",
  prodDomain: "emailreceiving.com",
  dextr: "dextr",
};

export const providers = {
  twilio: "twilio",
  amazonSes: "amazon-ses",
  oneClickSes: "Amazon-SES/1-Click",
};

export const DynamicsWizard = {
  get: "GET",
  post: "POST",
  patch: "PATCH",
  contactInitiated: "Contact Initiated",
  openUrl: "Open URL for Agent",
  contactCleared: "Contact Cleared",
  recordingsReady: "Recordings Ready",
  contactInitiatedResponse: "STEP2-Response",
  contactInitiatedResponseSecond: "STEP3-Response",
  openUrlResponse: "STEP4-Response",
  contactClearedResponse: "STEP5-Response",
  recordingsReadyResponse: "STEP6-Response",
  contactInitiatedStepOne: 2,
  contactInitiatedStepTwo: 3,
};

export const Permissions = {
  routing: "Routing",
  hoursOfOperations: "Hours of operations",
  phoneNumber: "Phone Number",
  communicationAndPresence: "Communication and Presence",
  teamStatus: "Team Status",
  announcements: "Announcements",
  raiseHand: "Raise Hand",
  directory: "Directory",
  silentMonitor: "Silent Monitor",
  changeOutboundQueue: "Change Outbound Queue",
  toggleRecording: "Toggle Recording",
  inlineHistory: "Inline History",
  smartReplies: "Smart Replies",
  usersAndPermissions: "Users and Permissions",
  users: "Users",
  securityProfiles: "Security Profiles",
  scripts: "Scripts",
  postCallSurveys: "Post Call Surveys",
  integration: "Integration",
  customLinks: "Custom Links",
  general: "General",
  channel: "Channel",
  reporting: "Reporting",
  routingProfileSelf: "Routing profile - self",
  globalContact: "Global Contact",
  activity: "Activity",
  ownActivity: "Own Activity",
  allActivity: "All Activity",
  sameQueue: "Same Queue",
  qualityAnalytics: "Quality/Analytics",
  recordings: "Recordings",
  transcripts: "Transcripts",
  analytics: "Analytics",
  dashboard: "Dashboard",
  agentPerformance: "Agent performance",
  recentActivity: "Recent Activity",
  averageTimes: "Average times",
  agentAvailability: "Agent Availability",
  contacts: "Contacts",
  contactsInQueue: "Contacts in Queue",
  channels: "Channels",
  video: "Video",
  inLineHistory: "Inline History",
};

export const backupFileExpirationDays = 5;
export const ENGAGE_TABS = {
  PROFILE: "profile",
  DETAILS: "details",
};
export const WebhookBasedTimer = 120;
