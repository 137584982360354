import format from "date-fns/format";
import isToday from "date-fns/is_today";
import isYesterday from "date-fns/is_yesterday";
import isTomorrow from "date-fns/is_tomorrow";
import isMonday from "date-fns/is_monday";
import isTuesday from "date-fns/is_tuesday";
import isWednesday from "date-fns/is_wednesday";
import isThursday from "date-fns/is_thursday";
import isFriday from "date-fns/is_friday";
import isSaturday from "date-fns/is_saturday";
import isSunday from "date-fns/is_sunday";
import differenceInCalendarDays from "date-fns/difference_in_calendar_days";

export default (date) => {
  if (!date) return "";

  const targetDate = new Date(date);
  const resultTime = format(targetDate, "hh:mm A");
  let resultDate = "";

  const difference = differenceInCalendarDays(targetDate, new Date());

  if (difference < -7) {
    let _date = format(targetDate, "MM/DD/YYYY");
    return `${_date} at ${resultTime}`;
  }

  if (difference >= -7 && difference < 0) {
    if (isMonday(targetDate)) {
      resultDate = "Last Monday at";
    }
    if (isTuesday(targetDate)) {
      resultDate = "Last Tuesday at";
    }
    if (isWednesday(targetDate)) {
      resultDate = "Last Wednesday at";
    }
    if (isThursday(targetDate)) {
      resultDate = "Last Thursday at";
    }
    if (isFriday(targetDate)) {
      resultDate = "Last Friday at";
    }
    if (isSaturday(targetDate)) {
      resultDate = "Last Saturday at";
    }
    if (isSunday(targetDate)) {
      resultDate = "Last Sunday at";
    }
  }

  if (isToday(targetDate)) {
    resultDate = "Today at";
  }

  if (isYesterday(targetDate)) {
    resultDate = "Yesterday at";
  }

  if (isTomorrow(targetDate)) {
    resultDate = "Tomorrow at";
  }

  return `${resultDate} ${resultTime}`;
};
