export default (status) => {
  if (status) {
    if (status.includes(";remote")) {
      status = status.replace(";remote", "");
    }

    if (status.includes("Not Ready-")) {
      status = status.replace("Not Ready-", "");
    }

    if (status.includes("Not Ready -")) {
      status = status.replace("Not Ready -", "");
    }

    var systemStates = {
      test1: "Error",
      test2: "AgentHungUp",
      BadAddressAgent: "BadAddressAgent",
      BadAddressCustomer: "BadAddressCustomer",
      Default: "Forced Release",
      FailedConnectAgent: "Connection Failed",
      FailedConnectCustomer: "Caller Hung-up",
      LineEngagedAgent: "LineEngagedAgent",
      LineEngagedCustomer: "LineEngagedCustomer",
      MissedCallAgent: "Forced Release",
      Custom: "Custom",
      MissedCallCustomer: "MissedCallCustomer",
      MultipleCcpWindows: "MultipleCcpWindows",
      RealtimeCommunicationError: "RealtimeCommunicationError",
      Busy: "Connected",
      Hold: "Connected",
      AfterContactWork: "After contact work",
      AfterCallWork: "After contact work",
      CallingCustomer: "Ringing",
      PendingBusy: "Connecting",
      Pending: "Connecting",
    };
    return systemStates[status] || status;
  } else return status;
};
